export const PLAYER = {
  APPLICATION_ID: 'BC_LIGHTBOX_APP',
  REWIND_AMOUNT: 20,
  RECOMMENDATIONS: 5
}

export const VHS_PLAYER = {
  id: 'player-vhs'
}

export const AUTOPLAY_TRAILER_PLAYER = {
  id: 'player-autoplay-trailer'
}

export const CONFIG = {
  premiumIntroductionEnabled: 'PREMIUM_INTRODUCTION_ENABLED',
  premiumIntroductionVersion: 'PREMIUM_INTRODUCTION_VERSION',
  androidAppStoreLink: 'URL_GOOGLE_PLAY_STORE_LINK',
  androidAppStoreIcon: 'URL_GOOGLE_PLAY_STORE_IMAGE',
  iOSAppStoreLink: 'URL_APPLE_APP_STORE_LINK',
  iOSAppStoreIcon: 'URL_APPLE_APP_STORE_IMAGE',
  facebookUsername: 'FACEBOOK_USERNAME',
  instagramUsername: 'INSTAGRAM_USERNAME',
  twitterUsername: 'TWITTER_USERNAME',
  deepLinkWatchUrl: 'DEEP_LINK_WATCH_URL'
}

export const DEFAULTS = {
  episodeGraphQLType: 'Episode',
  titleGraphQLType: 'Title',
  episodeVideoType: 'MOVIE',
  titleVideoType: 'MOVIE'
}

export const TIME_FORMAT_SHORT = {
  hours: 'h',
  minutes: 'm'
}

export const TIME_FORMAT = {
  hours: 'h',
  minutes: 'mins'
}

export const DURATION_FORMAT = {
  hours: 'hr',
  minutes: 'm'
}

export const FORM_VALUES = {
  password: {
    min: 6
  },
  pin: {
    length: 4
  },
  addProfile: {
    defaultParentalRating: 'R'
  },
  mobile: {
    min: 8,
    max: 14
  }
}

export const PHONE_REG_EXP = /^(0[1-9]\d+|00[1-9]\d+)$/

export const PHONE_MAX_LENGTH = 14

export const FORM_MESSAGES = {
  networkError:
    'We are unable to connect you right now. Please check your network and try again.',
  required: 'This field is required.',
  email: {
    match: 'Doesn’t match email address above.',
    valid: 'Email must be a valid email.'
  },
  pin: {
    min: `The PIN must be exactly ${FORM_VALUES.pin.length} characters.`,
    max: `The PIN must be exactly ${FORM_VALUES.pin.length} characters.`,
    match: 'Doesn’t match PIN inserted above.',
    valid: 'PIN must contain only numbers.'
  },
  password: {
    min: `The password must contain at least ${
      FORM_VALUES.password.min
    } characters.`,
    match: 'The passwords do not match.'
  },
  mobile: {
    min: `The mobile number must contain at least ${
      FORM_VALUES.mobile.min
    } characters.`,
    max: `The mobile number can contain maximum ${
      FORM_VALUES.mobile.max
    } characters.`,
    match: 'This is not a valid New Zealand number.'
  },
  validationError: 'Please complete all of the required fields above',
  voucherInvalid: 'There was an issue while validaing your voucher'
}

export const LOCAL_STORAGE = {
  reduxPersistPrefix: 'lbxw:'
}

export const PROFILES = {
  maxNumber: 5
}

export const LOADING_OVERLAY = {
  KEYS: {
    PLAYER_LOADING: 'PLAYER_LOADING'
  },
  availableOperations: {
    signup: true,
    login: true
  },
  operations: {
    validatePin: {
      loadingMsg: 'Validating PIN...', // Message to be displayed while loading
      successMsg: 'PIN Validated!' // Message to be displayed on success
    },
    signup: {
      loadingMsg: 'Creating account...', // Message to be displayed while loading
      successMsg: 'Account created!' // Message to be displayed on success
    },
    login: {
      loadingMsg: 'Logging in...', // Message to be displayed while loading
      successMsg: 'Login successful!' // Message to be displayed on success
    },
    logout: {
      loadingMsg: 'Logging out...', // Message to be displayed while loading
      successMsg: 'See you soon!' // Message to be displayed on success
    },
    profilesSwitch: {
      loadingMsg: 'Profile loading...', // Message to be displayed while loading
      successMsg: 'Profile loaded!' // Message to be displayed on success
    },
    profilesAdd: {
      loadingMsg: 'Creating profile...', // Message to be displayed while loading
      successMsg: 'Profile created!' // Message to be displayed on success
    },
    profileDelete: {
      loadingMsg: 'Deleting profile...', // Message to be displayed while loading
      successMsg: 'Profile deleted!' // Message to be displayed on success
    },
    profilesEdit: {
      loadingMsg: 'Editing profile...', // Message to be displayed while loading
      successMsg: 'Ta da! Changes saved!' // Message to be displayed on success
    },
    passwordChange: {
      loadingMsg: 'Changing password...', // Message to be displayed while loading
      successMsg: 'Password changed!' // Message to be displayed on success
    },
    playerLoading: {
      loadingMsg: 'Player loading...', // Message to be displayed while loading
      successMsg: 'Enjoy!' // Message to be displayed on success
    },
    subscribe: {
      loadingMsg: 'Subscribing...', // Message to be displayed while loading
      successMsg: 'Subscribed!' // Message to be displayed on success
    },
    paymentProcessing: {
      loadingMsg: 'Processing...', // Message to be displayed while loading
      successMsg: 'Processed!' // Message to be displayed on success
    }
  },
  successTimeout: 1000, // time interval to display success message in milliseconds
  processTimeout: 60000, // time interval to display overlay before process times out
  errorMsgTimeout: 4000, // time interval to display error message
  loadingMsg: 'Loading...', // Message to be displayed while loading
  successMsg: 'Done!', // Message to be displayed on success
  errorMsg: '\u00A0', // Message to be displayed on error
  timeoutErrorMsg:
    'It looks like something went wrong. Please check your connection and refresh the page to continue.' // Message to be displayed on network error
}

export const PIN = {
  expirationTime: 0 // PIN expiration time in minutes
}

export const SERIES_CONFIG = {
  upcomingEpisodes: 3,
  comingSoonEpisode: 'Coming Soon'
}

export const FLEXIBLE_SIZE_MODALS = [
  'login', 'profilesManagement', 'profilesAdd', 'profilesEdit', 'profilesSwitch', 'passwordForgotten', 'passwordReset',
  'login-rental', 'pinForgotten', 'userExists', 'welcomeBack', 'signup-tvod-cancel-add-payment', 'voucherValidateErrorForSignup'
]

export const LARGE_SIZE_MODALS = [
  'profilesAdd', 'profilesEdit'
]

export const MODALS = {
  // Constants for the query string parameter modal=<value>
  qsParams: {
    addVoucher: 'addVoucher',
    voucherValidateErrorForSignup: 'voucherValidateErrorForSignup',
    voucherValidateErrorAfterLogin: 'voucherValidateErrorAfterLogin',
    addSubscription: 'add-subscription',
    upgradeSubscription: 'upgrade-subscription',
    downgradeSubscription: 'downgrade-subscription',
    login: 'login',
    signup: 'signup',
    signupSubscription: 'signup-subscription', // subscription with sign up on step 1
    subscription: 'subscription', // subscription with voucher input on step 1
    subscriptionCancel: 'subscription-cancel',
    subscriptionResume: 'subscription-resume',
    subscriptionRepair: 'subscription-repair',
    subscriptionSelection: 'subscription-selection',
    subscriptionSelectionUpsell: 'subscription-selection-upsell', // subscription after rental flow
    subscriptionOffer: 'subscription-offer', // subscription offer before cancel
    subscriptionVoucherWarn: 'subscription-voucher-warn', // voucher warn before subscription change
    loginRental: 'login-rental',
    profilesManagement: 'profilesManagement',
    profilesAdd: 'profilesAdd',
    profilesEdit: 'profilesEdit',
    profilesSwitch: 'profilesSwitch',
    passwordForgotten: 'passwordForgotten',
    passwordReset: 'passwordReset',
    pinForgotten: 'pinForgotten',
    updatePaymentDetails: 'updatePaymentDetails',
    userExists: 'userExists',
    welcomeBack: 'welcomeBack',
    signupTVODCancelAddPayment: 'signup-tvod-cancel-add-payment'
  }
}

export const THEME_OPTIONS = {
  light: 'LIGHTBOX_KIDS',
  dark: 'LIGHTBOX_DARK'
}

export const DEFAULT_THEME = THEME_OPTIONS.dark
export const KIDS_DEFAULT_THEME = THEME_OPTIONS.light

export const VOUCHERS = {
  types: {
    SVOD: 'SVOD',
    TVOD: 'TVOD'
  },
  // Currently, all existing campaigns are set up with an uppercase alpha-numeric code
  // of at least 4 characters - minimum as set by Evergent
  CHAR_LIMIT: '4'
}

export const STATIC_SCREENS = {
  MY_LIST: {
    screenId: '/my-list',
    subType: 'MY_LIST'
  },
  MY_RENTALS: {
    screenId: '/my-rentals',
    title: 'My Rentals',
    subType: 'MY_RENTALS'
  },
  EVERGENT_IFRAME_RETURN_URL: {
    creditCardForm: '/credit-card-form'
  },
  TERMS: '/pages/terms',
  PRIVACY: '/pages/privacy'
}

export const ACCOUNT_SCREENS = {
  MY_ACCOUNT: '/my-account',
  ACCOUNT_DETAILS: '/my-account/details',
  SUBSCRIPTION_PAYMENT: '/my-account/subscription',
  DEVICES_DOWNLOADS: '/my-account/devices',
  MY_ACTIVITY: '/my-account/my-activity',
  DATA_SAVER: '/my-account/data_saver'
}

// Texts and urls of the buttons when empty my list or my rentals
export const BROWSE_MOVIES = {
  label: 'Browse Movies',
  url: '/movies'
}
export const BROWSE_TV = {
  label: 'Browse TV',
  url: '/tv'
}
export const BROWSE_LIGHTBOX = {
  label: 'Browse Lightbox',
  url: '/'
}
export const BROWSE_NEON = {
  label: 'Browse Neon',
  url: '/'
}
export const BROWSE_RENTALS = {
  label: 'Browse Rentals',
  url: '/rental'
}

export const PLAYBACK_ERRORS = [
  'NOT_FOUND',
  'DEVICE_NOT_FOUND',
  'PARENTAL_RATING_RESTRICTED',
  'MISSING_RENTAL',
  'MISSING_SUBSCRIPTION',
  'STREAMING_LIMIT_TVOD',
  'VIEWING_PERIOD_EXPIRED',
  'VIDEO_NOT_AVAILABLE',
  'DOWNLOAD_EXISTS'
]

export const PLAYBACK_ERROR_MESSAGES = {
  BC_PLAYER_ERROR:
    'The video cannot be played at this time. Please try again later.'
}

export const LOGIN_ERRORS = {
  WRONG_USERNAME_PASSWORD: 'NOT_FOUND'
}

export const PLAYBACK_INFO = {
  status: {
    finished: 'FINISHED',
    resume: 'IN_PROGRESS',
    start: 'NOT_STARTED'
  }
}

export const RENTALS = {
  showMinutesLimitInMinutes: 2,
  showHoursLimitInHours: 1,
  showDaysLimitInHours: 48
}

export const RENTAL_STATUS = {
  RENTED: 'RENTED',
  NOT_RENTABLE: 'NOT_RENTABLE', // Cannot rent and not already rented
  RENTABLE: 'RENTABLE',
  RENTABLE_AGAIN: 'RENTABLE_AGAIN'
}

export const IMGIX_CROP_OPTIONS = {
  KEY_ART: 'faces,right',
  HOTSPOT: 'faces,top,right',
  BRAND: 'faces,top'
}

export const SEARCH = {
  MIN_CHAR: 1,
  DEBOUNCE_WAIT: 500 // millisecs
}

export const HELP_SITE = {
  URL_HELP_SITE: 'URL_HELP_SITE',
  URL_FEEDBACK: 'URL_HELP_SITE_FEEDBACK_FORM'
}

export const MAINTENANCE_MODE = {
  ENABLED: 'MAINT_MODE_ENABLED',
  BACKGROUND: 'MAINT_MODE_BACKGROUND',
  POLL_INTERVAL: 'MAINT_MODE_POLL_INTERVAL'
}

// Status of the account from Evergent.
export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE', // Account is fully active and operational
  SUSPENDED: 'SUSPENDED', // Account has been suspended. Usually due to unable to process payment
  ONHOLD: 'ONHOLD', // Customer voluntarily put account on hold for a time
  CANCELLED: 'CANCELLED', // Customer or LBX has officially cancelled the account
  NONE: 'NONE', // No subscription was found for the account
  DOWNGRADING: 'DOWNGRADING' // Customer is downgrading their plan (ie. premium till the end of payment cycle and then standard)
}

export const EV_SUBSCRIPTION_STATUS = {
  ACTIVE: 'Active', // Order that is open and can still be processed by the renewal or retry batch processes
  ADD: 'Add', // It was used for existing customer orders when migrated to the Evergent platform
  FINAL_BILL: 'Final Bill', // Cancelled by customer or payment failure. Order won't be renewed
  HOLDONNPMT: 'HoldOnNpmt', // Used to place an order into a suspended state when the payment attempt has failed
  HOLDONREQ: 'HoldOnReq ', // Used to identify an account that has been suspended due to a customer action
  PAUSE: 'Pause'
}

export const SUBSCRIPTION_NAMES = {
  PREMIUM: 'Premium'
}

// Target types for navigation items
export const TARGET_TYPES = {
  EXTERNAL_URL: 'EXTERNAL_URL', // navigate to <url>
  SCREEN_ID: 'SCREEN_ID', // navigate to /uuid/<screenId>
  PATH: 'PATH' // navigate to <path>
}

export const MW_ERRORS = {
  RESTRICTED_GEOLOCATION: 'RESTRICTED_GEOLOCATION',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  EVERGENT_API_ERROR: 'EVERGENT_API_ERROR',
  INVALID_SECURITY_CODE: 'INVALID_SECURITY_CODE',
  STREAMING_LIMIT_SVOD: 'STREAMING_LIMIT_SVOD',
  STREAMING_LIMIT_SVOD_UPGRADE: 'STREAMING_LIMIT_SVOD_UPGRADE',
  VOUCHER_NOT_APPLICABLE: 'VOUCHER_NOT_APPLICABLE',
  USER_ALREADY_HAVE_ACTIVE_SUBSCRIPTION: 'ALREADY_HAVE_ACTIVE_SUBSCRIPTION'
}

export const MW_ERROR_MESSAGES = {
  INTERNAL_SERVER_ERROR_MESSAGE:
    'Hmm... excuse us - we had a server error. Would you mind trying again?',
  NOT_AUTHENTICATED: 'You are not authorized. Please login and try again.',
  STREAMING_LIMIT_SVOD:
    'It looks like more than four people are trying to watch Lightbox at the same time.',
  STREAMING_LIMIT_SVOD_UPGRADE:
    'It looks like more than two people are trying to watch Lightbox at the same time. Upgrade to Lightbox Premium to have four screens on the go at once – perfect for big households.',
  STREAMING_LIMIT_NEON: 'Sorry, it looks like multiple people are streaming Neon on this account. You can only stream two devices at once.'
}

export const MY_ACCOUNT = {
  PASSWORD_VALIDATION_INTERVAL: 5, // minutes
  CREDIT_CARD_LIMIT: 5
}

// The type of restriction to apply to movie/TVOD rental purchasing.
export const TVOD_PURCHASE_RESTRICTION = {
  PIN: 'PIN',
  PASSWORD: 'PASSWORD',
  NONE: 'NONE'
}

export const MEDIA_BAR_LAYOUTS = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT'
}

// screen size breakpoints, should be consistent to those defined in css
export const SCREEN_SIZE_BREAKPOINTS = {
  MIN_360: 360,
  MIN_480: 480,
  MIN_667: 667,
  MIN_768: 768,
  MIN_1024: 1024,
  MIN_1280: 1280,
  MIN_1920: 1920
}

export const INFINITE_CAROUSELS = ['MY_RENTALS', 'SEARCH']

// Slug used to navigate to a page by a content item's ID
export const UUID_SLUG = 'uuid'
// white theme = guest mode
// a black list used in `app/lib/utils.js` > `buildWhiteThemFilter`
export const WHITE_THEME_ROUTES_BLACK_LIST = [
  '/series',
  '/tv-series',
  '/movie',
  '/uuid',
  '/browse-movies',
  '/browse-rentals',
  '/movies-categories',
  '/halloween',
  '?search',
  '?modal',
  '?utm',
  '?email',
  '?voucher',
  '/neon-home-guest',
  '/signup',
  '?plan',
  '/home',
  '/movies',
  '/tv',
  '/rental',
  '/new-on-neon',
  '/blackfriday',
  '/kids'
]

/**
 * Routes where the rental theme should be used
 */
export const RENTAL_THEMED_PAGES = [
  '/browse-rentals',
  '/rental',
  '/my-rentals'
]

export const APP_PADDING = {
  DEFAULT: 20,
  MIN_480: 42,
  MIN_768: 50
}

export const AGE_RATINGS_HELP_ARTICLE =
  'https://help.neontv.co.nz/support/solutions/articles/64000203661-age-ratings-on-neon'

export const isSubscriptionDatalayerReleased = (() => {
  if (typeof window === 'undefined') {
    return false
  }
  return (
    process.env.SUBSCRIPTION_DATALAYER_RELEASED === 'true' ||
    /https:\/\/www.(test|dev\d).lightbox.co.nz/.test(window.location.href) ||
    /localhost:\d{4,6}/.test(window.location.href)
  )
})()

export const DEFAULT_KIDS_PROFILE_DETAIL = {
  ageGroup: 'OLDER_KIDS',
  avatar: '2',
  email: '',
  isKid: true,
  maxRating: 'PG',
  mobile: '',
  name: 'KIDS'
}

export const NAVIGATION_BAR_GA_KIDS_INSTANT_SWITCH = {
  label: 'Kids instant switch',
  action: 'Kids'
}

export const NAVIGATION_BAR_GA_KIDS_PROFILE_SELECTION = {
  label: 'Profile selection',
  action: 'Kids'
}

export const WARNING_CREATE_KIDS_WITH_NO_PROFILE_SLOT = 'Sorry, you will need to set up a Kids Profile to access our Kids section. Please remove a profile and try again.'

export const ADD_VOUCHER_SUCCESSFUL_MESSAGES = {
  successMsg: 'Your voucher code has been successfully applied.',
  confirmMsg: 'To confirm how many months you’re covered for, please check your voucher.'
}

export const DCB_API_ENDPOINT = process.env.APP_DCB_API_ENDPOINT
export const DCB_V2_API_ENDPOINT = process.env.APP_DCB_V2_API_ENDPOINT

export const MANAGE_PLAN_OPTIONS = {
  ADD: 'ADD',
  UPGRADE: 'UPGRADE',
  DOWNGRADE: 'DOWNGRADE',
  CANCEL: 'CANCEL',
  RESUME: 'RESUME'
}

export const VOUCHER_VALIDATE_FOR_SIGNUP = 'SIGNUP'
export const VOUCHER_VALIDATE_AFTER_LOGIN = 'AFTER_LOGIN'
export const VOUCHER_VALIDATE_AFTER_LOGIN_WITH_PLAN = 'AFTER_LOGIN_WITH_PLAN'

export const GENDERS = ['MALE', 'FEMALE', 'DIVERSE']

export const TOOLTIPS = [
  {
    name: 'birthYear',
    content: 'We’ll use this information to create a more personalised NEON experience'
  },
  {
    name: 'gender',
    content: 'We’ll use this information to create a more personalised NEON experience'
  }
]

// "You may also like" recommendation item limit on show page
export const YOU_MAY_ALSO_LIKE_ITEM_LIMIT = 15

export const PRICE_CHANGE_ALERT = 'price_change_alert'

export const KIDS_PROFILE_ADD_SUBSCRIPTION_ERROR_MESSAGE = 'Sorry, it looks like you don’t have a subscription. Switch to your main profile and visit your My Account section to add a Neon Plan and resume your Neon subscription.'

export const KIDS_PROFILE_PURCHASE_ERROR_MESSAGE = 'Rentals are not able to be purchased with a Kids profile. Please change profiles to complete this purchase.'

export const CAROUSELS_DO_NOT_LOOP_SLIDES = ['TOP_TEN']
